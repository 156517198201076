export default class Configurator {
    static async getParams(answers, family) {
        const { params } = await require(`@/config/forms/tool/${family.key}`);
        
        let summary = [];
  
        const keys = Object.keys(params);

        for (let i in keys) {
          summary.push({
            key: keys[i],
            // label: params[keys[i]] + (family[`${keys[i]}_units`] ? ` (${family[`${keys[i]}_units`]})` : ''),
            label: params[keys[i]],
            value: answers[keys[i]],
          })
        }
  
        return summary;
    }
}